<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="count-box">
      <!--      <div class="item color-1 animate__animated animate__slideInDown animate__faster">-->
      <!--        <div class="left">-->
      <!--          <i class="el-icon-user-solid"></i>-->
      <!--        </div>-->
      <!--        <div class="right">-->
      <!--          <div class="label">资方名称</div>-->
      <!--          <div class="value">{{ info.capitalName || '' }}</div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="item color-2 animate__animated animate__slideInDown animate__faster">-->
      <!--        <div class="left">-->
      <!--          <i class="el-icon-mobile"></i>-->
      <!--        </div>-->
      <!--        <div class="right">-->
      <!--          <div class="label">资方手机号</div>-->
      <!--          <div class="value">{{ info.capitalMobile || '' }}</div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="item color-3 animate__animated animate__slideInDown animate__faster">
        <div class="left">
          <i class="el-icon-coin"></i>
        </div>
        <div class="right">
          <div class="label">账户可用余额</div>
          <div class="value">
            <span>{{ info.availableAmount || 0 }}</span>
            <span
              class="withdraw-btn"
              :class="{ disabled: Number(info.availableAmount) <= 0 }"
              type="primary"
              size="mini"
              @click="handleReview"
              >余额提现</span
            >
          </div>
        </div>
      </div>
      <div class="item color-6 animate__animated animate__slideInDown animate__faster">
        <div class="left">
          <i class="el-icon-shopping-bag-1"></i>
        </div>
        <div class="right">
          <div class="label">提现中金额</div>
          <div class="value">{{ info.inWithdrawAmount || 0 }}</div>
        </div>
      </div>
      <div class="item color-4 animate__animated animate__slideInDown animate__faster">
        <div class="left">
          <i class="el-icon-money"></i>
        </div>
        <div class="right">
          <div class="label">累计充值金额</div>
          <div class="value">{{ info.rechargeAmount || 0 }}</div>
        </div>
      </div>
      <div class="item color-5 animate__animated animate__slideInDown animate__faster">
        <div class="left">
          <i class="el-icon-sell"></i>
        </div>
        <div class="right">
          <div class="label">累计提现金额</div>
          <div class="value">{{ info.withdrawAmount || 0 }}</div>
        </div>
      </div>
    </div>

    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="申请日期">
          <el-date-picker
            style="width:230px"
            v-model="timeSlot"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            size="mini"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="getList()">搜索</el-button>
          <el-button type="info" icon="el-icon-refresh" size="mini" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- <el-divider></el-divider> -->
    <div class="tabs">
      <div :class="['item', searchData.status === 0 ? 'active' : '']" @click="handleClickTab(0)">
        <el-badge>
          <span class="tab-name">全部</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 1 ? 'active' : '']" @click="handleClickTab(1)">
        <el-badge>
          <span class="tab-name">审核中</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 3 ? 'active' : '']" @click="handleClickTab(3)">
        <el-badge>
          <span class="tab-name">已打款</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 2 ? 'active' : '']" @click="handleClickTab(2)">
        <el-badge>
          <span class="tab-name">提现失败</span>
        </el-badge>
      </div>
    </div>
    <div class="head-btn">
      <div></div>
      <el-button
        v-checkbtn="isShowBtn(AUTH_BTN.capital_withdraw_list_export)"
        type="primary"
        icon="el-icon-download"
        size="mini"
        @click="handleExport"
        >导出</el-button
      >
    </div>

    <el-table :data="list" style="width: 100%">
      <el-table-column type="index" label="序号" :index="indexMethod" width="100"> </el-table-column>
      <el-table-column prop="createTime" label="申请时间" min-width="160"> </el-table-column>
      <el-table-column prop="withdrawNo" label="提现流水号" min-width="180"> </el-table-column>
      <el-table-column prop="amount" label="提现金额" min-width="100"> </el-table-column>

      <el-table-column prop="capitalName" label="提现状态" min-width="110">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status == 1" type="warning" size="small">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status == 2" type="danger" size="small">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status == 3" type="primary" size="small">{{ row.statusName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="remitTime" label="打款时间" min-width="160"> </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pageSize" @pagination="getList" />

    <!-- 提现审核弹窗 -->
    <el-dialog title="账户余额提现" :visible.sync="show_review" width="700px">
      <el-form ref="form" :model="withdrawData" label-width="90px">
        <el-form-item label="提现金额：">
          <el-input-number
            style="width:200px"
            v-model="withdrawData.amount"
            :precision="2"
            size="small"
            :step="1"
            step-strictly
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div style="height:50px"></div>
      <div slot="footer">
        <el-button @click="show_review = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="submitReview" size="mini">提 交</el-button>
      </div>
    </el-dialog>
    <div style="height:100px"></div>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getListAPI, DownloadAPI, getInfoAPI, withdrawApplyAPI } from './api'

export default {
  name: 'List',
  mixins: [authBtnMixin],
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],

      show_review: false,
      total: 0,
      list: [],
      searchData: {
        page: 1,
        pageSize: 10,
        leftCreateDate: '',
        rightCreateDate: '',
        status: 0
      },
      timeSlot: [],
      withdrawData: {
        amount: ''
      },

      info: {}
    }
  },

  mounted() {
    this.getList()
    this.getInfo()
  },

  methods: {
    async getList() {
      const { timeSlot } = this
      let params = { ...this.searchData }
      if (params.status == 0) {
        params.status = ''
      }
      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0]
        params.rightCreateDate = timeSlot[1]
      }

      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },
    async getInfo() {
      this.info = await getInfoAPI()
    },

    reset() {
      this.timeSlot = []
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    // 提现审核按钮
    handleReview() {
      if (Number(this.info.availableAmount) <= 0) return this.$message.warning('账户余额不足！')
      this.withdrawData = {
        amount: ''
      }

      this.show_review = true
    },
    // 提交审核
    submitReview() {
      if (this.withdrawData.amount <= 0) return this.$message.warning('提现金额必须大于0元')
      if (this.withdrawData.amount > this.info.availableAmount) return this.$message.warning('提现金额不能大于账户可用余额')
      withdrawApplyAPI(this.withdrawData).then(() => {
        this.$message.success('提现申请成功')
        this.show_review = false
        this.getList()
        this.getInfo()
      })
    },
    handleExport() {
      const { status, leftCreateDate, rightCreateDate } = this.searchData
      const { timeSlot } = this
      let params = { status, leftCreateDate, rightCreateDate }
      if (status == 0) {
        params.status = ''
      }
      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0]
        params.rightCreateDate = timeSlot[1]
      }

      DownloadAPI(params)
    },
    // 点击tab
    handleClickTab(i) {
      if (this.searchData.status == i) return
      this.searchData.status = i
      this.getList()
    },
    // 序号自增--方法
    indexMethod(index) {
      // 拿到页码
      const currentPage = this.searchData.page
      // 拿到每页记录条数
      const pageSize = this.searchData.pageSize
      // 根据值进行计算返回
      return index + 1 + (currentPage - 1) * pageSize
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-dialog {
    .el-dialog__body {
      padding-top: 10px;
    }
  }
  .el-divider--horizontal {
    margin: 10px 0 10px;
  }
  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
    border-bottom: 1px solid #dcdfe6;
    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 8px;
      margin-right: 20px;
      transition: all 0.5s linear;
      .tab-name {
        padding: 6px;
      }
    }
    .active {
      color: #409eff;
      font-weight: 600;
      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
  .head-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .el-form {
    .el-form-item {
      margin-right: 25px;
      margin-bottom: 0;
    }
  }
  .captial-detail {
    // display: flex;
    // align-items: center;
    // margin-bottom: 20px;
    padding-left: 20px;
    .row {
      display: flex;
      align-items: center;
      margin-right: 40px;
      margin-bottom: 10px;
      .label {
        display: flex;
        align-items: center;
        i {
          margin-right: 5px;
        }
      }
      .value {
        font-weight: 600;
      }
      .col {
        display: flex;
        align-items: center;
        margin-right: 40px;
      }
    }
  }
  .count-box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    gap: 20px;
    justify-content: space-around;
    margin-bottom: 30px;
    .item {
      display: flex;
      height: 60px;
      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        margin-right: 16px;
        border-radius: 4px;
        i {
          font-size: 26px;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .label {
          font-size: 14px;
          color: #606266;
        }
        .value {
          font-size: 18px;
          font-weight: 700;
          .withdraw-btn {
            font-weight: 400;
            margin-left: 10px;
            font-size: 14px;
            color: #fff;
            background: #409eff;
            padding: 2px 5px;
            font-size: 12px;
            border-radius: 4px;
            cursor: pointer;
          }
          .disabled {
            background: #a0cfff;
          }
        }
      }
    }
    .color-1 {
      .left {
        background: #eff7ff;
        i {
          color: #3491fa;
        }
      }
    }
    .color-2 {
      .left {
        background: #f9f5fe;
        i {
          color: #b27feb;
        }
      }
    }
    .color-3 {
      .left {
        background: #fff5eb;
        i {
          color: #ff7d00;
        }
      }
    }
    .color-4 {
      .left {
        background: #ecfbfb;
        i {
          color: #0fc6c2;
        }
      }
    }
    .color-5 {
      .left {
        background: #f8fded;
        i {
          color: #9fdb1d;
        }
      }
    }
    .color-6 {
      .left {
        background: #f9f5fe;
        i {
          color: #b27feb;
        }
      }
    }
  }
}
</style>
